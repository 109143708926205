import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
import SEO from "../components/seo"
import GatesWithForm from "../components/Resources/GatesWithForm.js"
import { withPreview } from "gatsby-source-prismic"

const Reports = ({ data }) => {
  const resource_link = data.prismicReports.data.link
  const seo_title = data.prismicReports.data.seo_title
  const seo_description = data.prismicReports.data.seo_description
  const seo_keywords = data.prismicReports.data.seo_keywords
  //const download = resource_link ? resource_link.url : null;
  //const article_content = data.prismicReports.data.description;
  const is_gated = data.prismicReports.data.is_gated === true ? true : false
  const description = data.prismicReports.data.description.text
    ? data.prismicReports.data.description.text
    : ""
  const og_description =
    description.length < 300 ? description : description.substring(0, 300) + `...`
  const og_image = data.prismicReports.data.thumbnail.fixed.src

  return (
    <Layout>
      <SEO title={seo_title} description={seo_description} keywords={seo_keywords} />
      <HeroWithoutImage title={`Resource Center / Reports`} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="" data-uk-grid>
            <div className="uk-width-expand">
              <h3>{data.prismicReports.data.title.text}</h3>
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: data.prismicReports.data.description.html }}
              />
            </div>
            <div className="uk-width-1-3@m">
              {is_gated && resource_link ? (
                <GatesWithForm
                  resourceKey={data.prismicReports.uid}
                  slogan="Get the Report"
                  marketoFormId="1569"
                >
                  <h3>Thank you</h3>
                  <a
                    className="uk-button uk-button-large uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                    href={resource_link.url}
                    target={resource_link.target}
                  >
                    Download Report <span uk-icon="download"></span>
                  </a>
                </GatesWithForm>
              ) : (
                <div className="GatesWithForm">
                  {resource_link && (
                    <a
                      className="uk-button uk-button-large uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                      href={resource_link.url}
                      target={resource_link.target}
                    >
                      Download Report <span uk-icon="download"></span>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Reports)
export const infographicsDetail = graphql`
  query reportsDetail($uid: String) {
    prismicReports(uid: { eq: $uid }) {
      data {
        seo_title
        seo_description
        seo_keywords
        is_gated
        thumbnail {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
          alt
        }
        description {
          html
          text
        }
        link {
          url
          target
        }
        title {
          html
          text
        }
      }
      uid
      last_publication_date
    }
  }
`
